.sidebar .pro-sidebar-inner {
  background: #BBB0A4 !important;
  border-radius:0px;
}

main.content {
  background: #F6F6F6;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vh ;
}
.sidebar .pro-icon-wrapper {
  background-color: transparent !important;
}
html {
  scroll-behavior: smooth;
}

.sidebar .pro-inner-item {
  padding: 5px 35px 5px 20px !important;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar .pro-inner-item:hover {
  color: #868dfb !important; /* Color on hover */
}

.sidebar .pro-menu-item.active, .sidebar .pro-inner-item.active {
  background-color: white !important; /* Active item background */
  color: blue !important; /* Active item text color */
  width: 96%; /* Adjust width */
  border-radius: 10px; /* Rounded corners for active item */
}

.sidebar .menu-item-custom {
  margin: 10px 0 20px 0; /* Margin for menu items */
  color: #BBB0A4; /* Color for menu items */
}

.sidebar .logo-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px; /* Adjust margin as needed */
}

.sidebar .profile-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px; /* Adjust margin */
}

.sidebar .profile-img {
  cursor: pointer;
  background-color: white; /* Profile image background */
  border-radius: 10px; /* Rounded corners */
  width: 180px; /* Set width */
  height: 150px; /* Set height */
}

.sidebar .menu-box {
  padding-left: 10%; /* Adjust padding */
}

.menu-item-custom {
  justify-content: center;
}

.menu-box .menu-item-custom img, 
.logout-item .MuiSvgIcon-root {
  margin-left: 30px;
  margin-right: 30px;
}

.logo-img{
  margin: 0 auto;
  display: block;
  width: 60px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
  
li.pro-menu-item {
  margin-top: -16px;

}

.pro-sidebar.collapsed {
  width: 134px;
}

.pro-sidebar .pro-menu .pro-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.logo-img {
  margin: 0 auto;
  display: block;
  /* width: 60px; */
  margin-top: 15px;
  margin-bottom: 10px;
}

img.logo-img {
  margin-left: 27px;
}

.sidebar-icon {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}


.sidebar {
  border-radius: 25px 0px 0px 0px; /* Add border radius */
  /* overflow: hidden; Ensure the rounded corners are displayed */
  min-height: 100vh;
}