$focused-outline-color: #4c9ffe;

.Action {
  display: flex;
  width: 12px;
  
  padding-left: 5px;
  padding-right: 15px;
  // padding-top: 10px;
  // padding-bottom: 10px;

  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  // @media (hover: hover) {
  //   &:hover {
  //     background-color: var(--action-background, rgba(0, 0, 0, 0.05));

  //     svg {
  //       fill: #6f7b88;
  //     }
  //   }
  // }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    // fill: #919eab;
  }

  &:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05));

    svg {
      fill: var(--fill, #788491);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px $focused-outline-color;
  }
}
